<template>
  <div class="content capital-account-list">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增资金账户') }}
      </a-button>
    </section>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-input allow-clear v-model.trim="formData.name" :placeholder="$t('请输入账户名称')" />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            :list="$store.state.settlementBuList"
            :placeholder="$t('结算组织')"
            :code.sync="formData.businessUnitCode"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showSelect="true"
      :scroll="{ x: 1500 }"
      :selectedRowKeys.sync="selectedRowKeys"
      :total="total"
      rowKey="id"
      @select="onSelectChange"
      @search="getList"
    >
      <template v-slot:header>
        <OperateBtn
          api="operateCapitalAccount"
          :text="$t('启用')"
          :params="{
            idList: selectedRowKeys,
            status: 1,
          }"
          @success="searchHandle"
        />
        <OperateBtn
          class="ml10"
          api="operateCapitalAccount"
          type="danger"
          :text="$t('停用')"
          :params="{
            idList: selectedRowKeys,
            status: 0,
          }"
          @success="searchHandle"
        />
      </template>
      <template v-slot:code="{ text, record }">
        <a @click="viewDetail(record)">{{ text }}</a>
      </template>
      <template v-slot:status="{ text }">
        <span class="text-success" v-if="+text === 1">{{ $t('启用') }}</span>
        <span class="text-danger" v-else>{{ $t('停用') }}</span>
      </template>
      <template v-slot:action="{ record }">
        <a-button type="link" @click="queryItemList(record)" v-if="record.isFinishInit">
          {{ $t('查看余额明细') }}
        </a-button>
        <a-button type="link" @click="initAccount(record)" v-else>
          {{ $t('账户余额初始化') }}
        </a-button>
      </template>
    </CommonTable>
    <a-modal v-model="visible" :title="$t('资金账户余额明细')" width="800px" :footer="null">
      <CommonTable
        :columns.sync="modalColumns"
        :dataSource="modalData"
        :showPagination="false"
        rowKey="id"
        :showSetting="false"
      ></CommonTable>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'CapitalAccountList',
  components: { CommonTable },
  data() {
    return {
      docType: 'CapitalAccount',
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [],

      visible: false,
      modalColumns: [],
      modalData: [],
    }
  },
  mounted() {
    this.setColumns()
  },
  activated() {
    this.searchHandle()
  },
  computed: {},
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.setColumns()
      this.searchHandle(1)
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('银行账号'),
          dataIndex: 'code',
          width: 200,
          scopedSlots: { customRender: 'code' },
        },
        {
          title: this.$t('账户名称'),
          dataIndex: 'name',
        },
        {
          title: this.$t('金融机构类别'),
          dataIndex: 'type',
          customRender: (text) => this.$store.getters.capitalTypeMapping[text],
        },
        {
          title: this.$t('状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'businessUnitName',
        },
        {
          title: this.$t('银行名称'),
          dataIndex: 'bankName',
        },
        {
          title: this.$t('账户用途'),
          dataIndex: 'accountPurpose',
          customRender: (text) => this.$store.getters.accountPurposeMapping[text],
        },
        {
          title: this.$t('存款类型'),
          dataIndex: 'subType',
          customRender: (text) => this.$store.getters.bankCapitalTypeMapping[text],
        },
        {
          title: this.$t('余额'),
          dataIndex: 'balance',
          customRender: (text, record) =>
            this.$currencySymbol(record.balanceList[0]?.balance, record.currency),
        },
        {
          title: this.$t('操作'),
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ]
      this.modalColumns = [
        {
          title: this.$t('银行账号'),
          dataIndex: 'capitalAccountCode',
          width: 200,
        },
        {
          title: this.$t('账户名称'),
          dataIndex: 'capitalAccountName',
        },
        {
          title: this.$t('支付方式'),
          dataIndex: 'settlementMethodName',
        },
        {
          title: this.$t('余额'),
          dataIndex: 'balance',
          customRender: (text, record) => this.$currencySymbol(record.balance, record.currency),
        },
        {
          title: this.$t('更新时间'),
          dataIndex: 'updatedAt',
        },
      ]
    },
    searchHandle(pageNo) {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({ name: 'capitalAccountDetail' })
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getCapitalAccountList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'capitalAccountDetail/' + record.code,
        name: 'capitalAccountDetail',
        query: {
          id: record.id,
          sn: record.code,
        },
        route: `/capitalManage/capitalAccountDetail?id=${record.id}&sn=${record.code}`,
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    queryItemList(record) {
      http({
        url: api.getCapitalItemList,
        data: {
          capitalAccountId: record.id,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.visible = true
          this.modalData = result.list
        },
      })
    },
    initAccount(record) {
      // console.log('record: ', record)
      localStorage.setItem('capitalAccount', JSON.stringify(record))
      this.$router.push({
        name: 'capitalAccountInitDetail',
      })
    },
  },
}
</script>
